import {
  InMemoryWebStorage,
  UserManager,
  WebStorageStateStore,
} from "oidc-client-ts";

const {
  REACT_APP_AUTH_URL: URL = "",
  REACT_APP_AUTH_CUSTOMER_CLIENT_ID: CLIENT_ID = "",
} = process.env;

const { origin } = globalThis.location;

const userManager = new UserManager({
  authority: URL,
  client_id: CLIENT_ID,
  redirect_uri: `${origin}/login`,
  response_type: "code",
  scope: "openid",
  accessTokenExpiringNotificationTimeInSeconds: 3,
  silent_redirect_uri: `${origin}/login/silent`,
  userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  post_logout_redirect_uri: `${origin}/logout/callback`,
});

// TODO: Listen for "userUnloaded" or other relevant event and redirect to login page directly instead of logout to landing page.
// This has to be coordinated with the retry and/or logoutLink in apollo client setup that do the logout if 401 is received.

export { userManager };
